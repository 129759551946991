// Row style typically used with selectable checkbox/radio
.selectable-row {
  @apply z-10 mt-2 flex min-h-[4rem] cursor-pointer items-center justify-between rounded-md px-7 py-2 hover:bg-gray-50;
  &.disabled {
    @include disabled();
  }
  fieldset[disabled] & {
    @apply cursor-default hover:bg-transparent;
  }
  &.active {
    @apply bg-grape-50 border-grape-200;
    fieldset[disabled] & {
      @apply bg-grape-50/50 border-grape-200/50;
    }
  }
  &.last {
    @apply mb-0;
  }
  &-details {
    @apply flex grow items-center;
  }
  &-checkbox {
    @apply mr-3;
  }
  &-heading {
    @apply mb-0;
    &.disabled {
      @apply opacity-50;
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes snow {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px 300px;
  }
}

@keyframes bodyLaugh {
  0% {
    top: 0px;
  }

  2% {
    top: -3px;
  }

  4% {
    top: 0px;
  }

  8% {
    top: -3px;
  }

  10% {
    top: 0px;
  }

  12% {
    top: -3px;
  }

  14% {
    top: 0px;
  }

  18% {
    top: -3px;
  }

  20% {
    top: 0px;
  }

  22% {
    top: -3px;
  }

  24% {
    top: 0px;
  }

  28% {
    top: -3px;
  }

  30% {
    top: 0px;
  }

  100% {
    top: 0px;
  }
}

@keyframes beardLaugh {
  0% {
    bottom: -28px;
  }

  2% {
    bottom: -30px;
  }

  4% {
    bottom: -28px;
  }

  8% {
    bottom: -30px;
  }

  10% {
    bottom: -28px;
  }

  12% {
    bottom: -30px;
  }

  14% {
    bottom: -28px;
  }

  18% {
    bottom: -30px;
  }

  20% {
    bottom: -28px;
  }

  22% {
    bottom: -30px;
  }

  24% {
    bottom: -28px;
  }

  28% {
    bottom: -30px;
  }

  30% {
    bottom: -28px;
  }

  100% {
    bottom: -28px;
  }
}

@keyframes headLaugh {
  0% {
    bottom: 83px;
  }

  45% {
    bottom: 83px;
  }

  50% {
    bottom: 90px;
  }

  92% {
    bottom: 90px;
  }

  98% {
    bottom: 83px;
  }

  100% {
    bottom: 83px;
  }
}

@keyframes mouthLaugh {
  0% {
    width: 20px;
  }

  45% {
    width: 20px;
  }

  50% {
    width: 15px;
  }

  92% {
    width: 15px;
  }

  98% {
    width: 20px;
  }

  100% {
    width: 20px;
  }
}

@keyframes presentFall {
  0% {
    top: 0px;
    transform: rotate(0deg);
  }
  100% {
    top: 100vh;
    transform: rotate(360deg);
  }
}

.dropdown-menu {
  @include select-default();
  @apply cursor-pointer;
  &.dropdown-menu-no-chevron {
    @apply bg-none pr-3;
  }
}
.dropdown-menu-winter {
  @apply bg-transparent text-white;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBfbmdjb250ZW50LWRvdC1jMTAzPSIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHlsZT0iaGVpZ2h0OiAxNnB4OyBzdHJva2Utd2lkdGg6IDJweDsgd2lkdGg6IDE2cHg7Ij48cGF0aCBfbmdjb250ZW50LWRvdC1jMTAzPSIiIGQ9Ik0xOSA5TDEyIDE2TDUgOSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD48L3N2Zz4=);
}
.dropdown-menu-dark {
  @apply border-transparent bg-gray-900 text-white hover:bg-gray-900 hover:outline hover:outline-gray-200;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBfbmdjb250ZW50LWRvdC1jMTAzPSIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHlsZT0iaGVpZ2h0OiAxNnB4OyBzdHJva2Utd2lkdGg6IDJweDsgd2lkdGg6IDE2cHg7Ij48cGF0aCBfbmdjb250ZW50LWRvdC1jMTAzPSIiIGQ9Ik0xOSA5TDEyIDE2TDUgOSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48L3BhdGg+PC9zdmc+);
}

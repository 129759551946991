.widgets-wrapper {
  @apply flex grow flex-wrap items-center;
  > * {
    @apply mb-2 flex-grow px-6;
  }
  &.widget-wrapper-80\/20 {
    > * {
      @apply w-4/6 basis-4/6;
      &:nth-child(2) {
        @apply w-2/6 basis-2/6;
      }
    }
    &.widgets-wrapper-dark {
      > * {
        &:nth-child(2),
        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          @apply border-l-grape-300;
        }
      }
    }
    &.widgets-wrapper-light {
      > * {
        &:nth-child(2),
        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          @apply border-l-gray-200;
        }
      }
    }
  }
  &.widget-wrapper-4-children {
    &.widgets-wrapper-dark {
      > * {
        &:nth-child(2n + 2) {
          @apply border-l-grape-300;
        }
      }
    }
    &.widgets-wrapper-light {
      > * {
        &:nth-child(2n + 2) {
          @apply border-l-gray-200;
        }
      }
    }
    > * {
      @apply w-1/2 basis-1/2;
      &:nth-child(2n + 2) {
        @apply border-l;
      }
    }
  }
  &:not(.widget-wrapper-80\/20) {
    > * {
      @apply w-2/6 basis-2/6;
    }
  }
  &:not(.widget-wrapper-4-children) {
    &.widgets-wrapper-dark {
      > * {
        &:nth-child(2),
        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          @apply border-l-grape-300;
        }
      }
    }
    &.widgets-wrapper-light {
      > * {
        &:nth-child(2),
        &:nth-child(3n + 2),
        &:nth-child(3n + 3) {
          @apply border-l-gray-200;
        }
      }
    }
    > * {
      &:nth-child(2),
      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        @apply border-l;
      }
    }
  }
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";

// Move this outside the tailwind layers as its dynamically loaded
@import "./app/shared/styles/components/_img-cropper.scss";
@import "./app/shared/components/tooltip/_tooltip.scss";

@import "./app/shared/styles/_mixins.scss";
@import "./app/shared/styles/_fonts.scss";
@import "./app/shared/styles/winter";

// Base Tailwind layer
@layer base {
  @import "./app/shared/styles/_base.scss";
}

// Components Tailwind layer
@layer components {
  @import "./app/shared/styles/components/_buttons.scss";
  @import "./app/shared/styles/components/_ng-select.scss";
  @import "./app/shared/styles/components/_card.scss";
  @import "./app/shared/styles/components/_selectable-row.scss";
  @import "./app/shared/styles/components/_links.scss";
  @import "./app/shared/styles/components/_badge.scss";

  @import "./app/shared/components/devices/_devices.scss";
  @import "./app/shared/components/browser-mock/_browser-mock.scss";
  @import "./app/shared/components/dropdown/_dropdown.scss";
  @import "./app/shared/components/widgets/_widgets.scss";
}

// Utilities Tailwind layer
@layer utilities {
  @import "./app/shared/styles/_utils.scss";
}

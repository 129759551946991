.ng-select {
  @apply h-11 focus-visible:outline-none #{!important};
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply ring-grape-500 bg-white shadow-none ring-2 #{!important};
}
.ng-select .ng-select-container {
  @apply flex h-full items-center overflow-visible border-gray-300 hover:cursor-pointer hover:bg-gray-50 hover:shadow-none #{!important};
  &[disabled] {
    @apply cursor-default #{!important};
  }
}
.ng-select .ng-select-container .ng-value-container {
  @apply pl-3 #{!important};
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  @apply items-center overflow-visible border-gray-200 text-base #{!important};
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  @apply text-gray-400 #{!important};
}
.ng-select .ng-value-label {
  @apply mr-2 flex items-center font-medium #{!important};
}
.ng-select.ng-select-opened > .ng-select-container {
  @apply border-gray-200 #{!important};
}
.ng-select.ng-select.ng-select-opened .ng-select-container .ng-value-container .ng-value {
  @apply text-gray-400 #{!important};
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  @apply text-base #{!important};
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  @apply top-0 pl-3 #{!important};
}
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
.ng-select.ng-select-opened.ng-select-top > .ng-select-container,
.ng-dropdown-panel .ng-dropdown-panel-items {
  @apply rounded-md #{!important};
}
.ng-select .ng-dropdown-panel.ng-select-bottom {
  @apply mt-[5px] rounded-md border-none shadow-md #{!important};
}
.ng-select .ng-dropdown-panel.ng-select-top {
  @apply mb-[5px] rounded-md border-none shadow-md #{!important};
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  @apply pl-3 text-base text-gray-900 #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-grape-50 font-normal text-gray-900 #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  .svg {
    @apply text-white #{!important};
  }
  @apply bg-grape-700 text-white #{!important};
}
.ng-select.ng-select-filtered .ng-placeholder {
  @apply hidden #{!important};
}
.ng-select .ng-select-container .ng-arrow-wrapper .ng-arrow {
  @apply top-0 block h-3 w-3 border-0 border-transparent bg-center bg-no-repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMjkyOTIzIDAuMjkyODkzQzAuNjgzNDQ3IC0wLjA5NzYzMSAxLjMxNjYxIC0wLjA5NzYzMSAxLjcwNzEzIDAuMjkyODkzTDUuMDAwMDIgMy41ODU3OUw4LjI5MjkxIDAuMjkyODkzQzguNjgzNDMgLTAuMDk3NjMxMSA5LjMxNjYgLTAuMDk3NjMxMSA5LjcwNzEyIDAuMjkyODkzQzEwLjA5NzYgMC42ODM0MTcgMTAuMDk3NiAxLjMxNjU4IDkuNzA3MTIgMS43MDcxMUw1LjcwNzEzIDUuNzA3MTFDNS4zMTY2MSA2LjA5NzYzIDQuNjgzNDQgNi4wOTc2MyA0LjI5MjkyIDUuNzA3MTFMMC4yOTI5MjMgMS43MDcxMUMtMC4wOTc2MDA0IDEuMzE2NTggLTAuMDk3NjAwNCAwLjY4MzQxNyAwLjI5MjkyMyAwLjI5Mjg5M1oiIGZpbGw9IiMxMTE4MjciLz4NCjwvc3ZnPg0K) !important;
  background-size: 12px !important;
}
.ng-select .ng-arrow-wrapper {
  @apply right-[10px] w-[12px] #{!important};
}
.ng-select.ng-select-opened .ng-select-container .ng-arrow-wrapper .ng-arrow {
  @apply rotate-180 #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  @apply items-center text-base text-gray-900 #{!important};
  input[type="checkbox"] {
    @apply mt-0 mr-2 #{!important};
  }
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  @apply pl-5 #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  @apply flex items-center px-3 text-base font-semibold text-gray-900 #{!important};
  input[type="checkbox"] {
    @apply mr-2 #{!important};
  }
}
.ng-select.ng-select-site-switcher {
  &[disabled] {
    @apply opacity-50 #{!important};
  }
  .ng-select-site-switcher-all-sites {
    @apply -mx-3 -my-2 border-0 border-b border-solid border-gray-200 px-3 py-2 text-base #{!important};
  }
  &.ng-select-opened {
    .ng-select-container {
      @apply ring-primary ring-1 #{!important};
    }
  }
  .ng-select-container {
    @apply rounded-md border-transparent #{!important};
    .ng-arrow-wrapper .ng-arrow {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMjkyOTIzIDAuMjkyODkzQzAuNjgzNDQ3IC0wLjA5NzYzMSAxLjMxNjYxIC0wLjA5NzYzMSAxLjcwNzEzIDAuMjkyODkzTDUuMDAwMDIgMy41ODU3OUw4LjI5MjkxIDAuMjkyODkzQzguNjgzNDMgLTAuMDk3NjMxMSA5LjMxNjYgLTAuMDk3NjMxMSA5LjcwNzEyIDAuMjkyODkzQzEwLjA5NzYgMC42ODM0MTcgMTAuMDk3NiAxLjMxNjU4IDkuNzA3MTIgMS43MDcxMUw1LjcwNzEzIDUuNzA3MTFDNS4zMTY2MSA2LjA5NzYzIDQuNjgzNDQgNi4wOTc2MyA0LjI5MjkyIDUuNzA3MTFMMC4yOTI5MjMgMS43MDcxMUMtMC4wOTc2MDA0IDEuMzE2NTggLTAuMDk3NjAwNCAwLjY4MzQxNyAwLjI5MjkyMyAwLjI5Mjg5M1oiIGZpbGw9IiMxMTE4MjciLz4NCjwvc3ZnPg0K) !important;
    }
  }
  &:not(.ng-select-site-switcher-inverted) {
    &.ng-select-opened {
      .ng-select-container {
        @apply bg-white text-gray-900 #{!important};
        .ng-value-container {
          .ng-placeholder {
            @apply bg-white text-gray-900 #{!important};
          }
        }
      }
    }
    .ng-select-container {
      @apply bg-slate-800 text-white #{!important};
      .ng-arrow-wrapper .ng-arrow {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMjkyOTIzIDAuMjkyODkzQzAuNjgzNDQ3IC0wLjA5NzYzMSAxLjMxNjYxIC0wLjA5NzYzMSAxLjcwNzEzIDAuMjkyODkzTDUuMDAwMDIgMy41ODU3OUw4LjI5MjkxIDAuMjkyODkzQzguNjgzNDMgLTAuMDk3NjMxMSA5LjMxNjYgLTAuMDk3NjMxMSA5LjcwNzEyIDAuMjkyODkzQzEwLjA5NzYgMC42ODM0MTcgMTAuMDk3NiAxLjMxNjU4IDkuNzA3MTIgMS43MDcxMUw1LjcwNzEzIDUuNzA3MTFDNS4zMTY2MSA2LjA5NzYzIDQuNjgzNDQgNi4wOTc2MyA0LjI5MjkyIDUuNzA3MTFMMC4yOTI5MjMgMS43MDcxMUMtMC4wOTc2MDA0IDEuMzE2NTggLTAuMDk3NjAwNCAwLjY4MzQxNyAwLjI5MjkyMyAwLjI5Mjg5M1oiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==") !important;
      }
      .ng-value-container {
        .ng-placeholder {
          @apply bg-slate-800 text-base text-white #{!important};
        }
      }
    }
  }
}
.ng-select.ng-select-no-border .ng-select-container {
  @apply border-transparent #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  @apply bg-grape-50 #{!important};
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  @apply p-0 #{!important};
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  @apply relative top-0 pb-0 pl-0 text-base #{!important};
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  @apply h-full pt-0 pl-3 #{!important};
}
.ng-select.ng-select-multiple .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  @apply bg-grape-50 text-gray-900 #{!important};
}
.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  @apply bg-white font-semibold text-gray-900 #{!important};
}
// Disabled states - leave at the bottom of this file so they don't get overridden by the above (aka specificity)
.ng-select > .ng-select-container {
  fieldset[disabled] & {
    @include disabled();
    @apply bg-transparent #{!important};
  }
}
.ng-select.ng-select-disabled > .ng-select-container {
  @include disabled();
  @apply bg-transparent #{!important};
}

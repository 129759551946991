@import "./iphone-14-pro/_iphone-14-pro.scss";

.device,
.device::before,
.device::after,
.device *,
.device *::before,
.device *::after {
  @apply box-border block;
}

.device {
  @apply relative z-10 scale-100;
  &-frame {
    @apply z-20;
  }
  &-content {
    @apply rounded-4xl h-full overflow-hidden bg-white px-3 pt-20 pb-4;
    &:empty {
      @apply hidden;
    }
    & > :first-child {
      @apply h-full w-full;
    }
  }
  &-content-placeholder {
    @apply relative bg-white bg-cover bg-center object-cover;
  }
}

.device-has-content {
  .device-iphone-14-pro {
    .device-top-bar {
      @apply text-black;
    }
    .device-battery {
      @apply border-black;
      &:before,
      .device-battery-level {
        @apply bg-black;
      }
    }
    .device-network {
      @apply text-black;
    }
    .device-signal {
      > span {
        &:before,
        &:after {
          @apply bg-black;
        }
      }
      &:before,
      &:after {
        @apply bg-black;
      }
    }
  }
}

.device-iphone-14-pro {
  @apply rounded-[68px] shadow-xl;

  .device-content-placeholder {
    @apply rounded-4xl h-full w-full;
  }

  .device-top-bar {
    @apply absolute z-50 flex w-full flex-row flex-wrap content-center items-center justify-between text-white;
    padding: 0 60px;
    top: 39.5px;
    height: 20px;
  }

  .device-top-bar-right {
    @apply relative flex flex-row flex-wrap content-center items-center justify-end;
    right: -12px;
  }

  .device-battery {
    @apply relative flex flex-row flex-wrap content-center items-center justify-center border border-white border-opacity-50;
    width: 18px;
    height: 11px;
    border-radius: 2px;
    &:before {
      @apply h-1/2;
      right: -2px;
      width: 2px;
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
    }
    .device-battery-level {
      @apply w-1/2;
      left: 1px;
      height: 7px;
      border-radius: 1px;
      max-width: calc(100% - 2px);
    }
    &:before,
    .device-battery-level {
      @apply absolute bg-white;
      content: "";
    }
  }

  .device-network {
    @apply relative mx-1 text-sm font-bold text-white;
  }

  .device-signal {
    @apply relative flex flex-row flex-wrap content-center items-center justify-center overflow-hidden;
    width: 18px;
    height: 10px;
    > span {
      &:before {
        right: 8px;
        height: 60%;
      }
      &:after {
        right: 12px;
        height: 40%;
      }
      &:before,
      &:after {
        @apply absolute bottom-0 bg-white;
        content: "";
        width: 3px;
        border-radius: 5px;
      }
    }
    &:before {
      right: 0;
      height: 100%;
    }
    &:after {
      right: 4px;
      height: 80%;
    }
    &:before,
    &:after {
      @apply absolute bottom-0 bg-white;
      content: "";
      width: 3px;
      border-radius: 5px;
    }
  }

  .device-time {
    @apply relative text-sm font-bold;
  }
}

@mixin disabled() {
  @apply pointer-events-none cursor-default opacity-50;
}

@mixin button-default() {
  @apply relative inline-flex cursor-pointer items-center rounded-md border border-solid px-4 py-3 text-base font-medium;
}

@mixin button-primary() {
  @apply bg-grape-700 hover:bg-grape-800 active:bg-grape-800 border-grape-700 text-white;
  &[disabled] {
    @include disabled();
  }
}

@mixin button-secondary() {
  @apply text-grape-700 bg-grape-100 hover:bg-grape-200 active:bg-grape-200 border-grape-100;
  &[disabled] {
    @include disabled();
  }
}

@mixin button-tertiary() {
  @apply border-gray-300 bg-white text-gray-700 hover:bg-gray-50 active:bg-gray-100;
  &[disabled] {
    @include disabled();
  }
}

@mixin button-warn() {
  @apply bg-ruby-500 hover:bg-ruby-600 active:bg-ruby-700 text-white;
  &[disabled] {
    @include disabled();
  }
}

@mixin select-default() {
  @apply focus:ring-grape-500 focus:border-grape-500 cursor-pointer rounded-md border-gray-300 bg-no-repeat py-3 pl-3 pr-10 text-sm  placeholder-gray-400 shadow-sm;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBfbmdjb250ZW50LWRvdC1jMTAzPSIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHlsZT0iaGVpZ2h0OiAxNnB4OyBzdHJva2Utd2lkdGg6IDJweDsgd2lkdGg6IDE2cHg7Ij48cGF0aCBfbmdjb250ZW50LWRvdC1jMTAzPSIiIGQ9Ik0xOSA5TDEyIDE2TDUgOSIgc3Ryb2tlPSIjMzc0MTUxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiPjwvcGF0aD48L3N2Zz4=);
  background-size: 16px;
  background-position: right 0.5rem center;
}
